<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- <img class="banner_box" :src="this.url.imgUrl + bannerImg" alt="勤学教育" /> -->
    <!-- 导航 -->
    <div id="nav_bar_box">
      <ul>
        <li>
          <div
            :class="[isSelect == item.id ? 'nav_name_active' : '']"
            v-for="item in nav_name"
            :key="item.tag_id"
            @click="getTeacher(item.id)"
          >
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
    <!-- 版心 -->
    <div id="content">
      <!-- 师资力量内容 -->
      <div class="teacher_box">
        <ul class="teacher">
          <!--  -->
          <!--  -->
          <li v-for="item in teachers" :key="item.id">
            <div class="teacher_top">
              <img :src="item.image" alt="勤学教育" />
              <!-- <img src="../../assets/img/big_teacher.png" alt="勤学教育">
              <img src="../../assets/img/small_teacher.png" alt="勤学教育">-->
              <div class="teacher_right">
                <div class="teacher_name">{{ item.name }}</div>
                <!-- <div id="short_term"></div> -->
                <span class="major">{{ item.brief_desc }}</span>
                <div class="division"></div>
                <div class="synopsis">{{ item.detail_desc }}</div>
              </div>
            </div>
            <!-- <div id="line"></div> -->
          </li>
        </ul>
      </div>
      <!-- <img :src="cdnUrl+img" alt="勤学教育"> -->
      <!-- 版心结尾 -->
    </div>
    <div class="pagesize">
      <el-pagination
        @current-change="pageclick"
        :current-page="datas.page"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      total: 0,
      bannerImg: "/assets/img/teacher.jpg", //banner图
      nav_name: [], //导航名字
      teachers: [], //师资
      tag_id: "", // 标签id
      isSelect: "", // 导航栏是否呗选中
      datas: {
        page: 1, //页码，默认1
        page_size: 10, //每页显示多少条，默认10
        type: 2, //资讯类型，默认1 1教育资讯 0平台资讯
        course_category: "", //资讯所属科目类别
      },
    };
  },

  methods: {
    // 师资力量标签接口
    teacherTag() {
      api.category_list().then((res) => {
        let a = [];
        res.data.data.list.forEach((e) => (a = [...a, ...e.children]));
        this.nav_name = a;
        this.isSelect = res.data.data.list[0].children[0].id;
        this.datas.course_category = res.data.data.list[0].children[0].id;
        this.getTeachersList();
      });
    },
    pageclick(e) {
      console.log(e);
      this.datas.page = e;
      this.getTeachersList();
    },
    getTeachersList() {
      api.faculty_list_new(this.datas).then((res) => {
        this.teachers = res.data.data.list;
        this.total = res.data.data.total_item_count;
      });
    },
    // 标签点击事件
    getTeacher(id) {
      this.isSelect = id;
      this.datas.course_category = id;
      this.datas.page = 1;
      this.getTeachersList();
    },
  },
  created() {
    this.teacherTag();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination.is-background .el-pager li {
  background: #fff;
}
.pagesize {
  width: 1200px;
  margin: 0 auto 30px;
  display: flex;
  justify-content: flex-end;
}
#app {
  background: #f5f5f5;
}
.banner_box {
  width: 100%;
  /* height: 360px; */
  height: 400px;
  background: #eee;
}

/* 导航 */

#nav_bar_box {
  width: 1200px;
  background: #fff;
  height: 70px;
  margin: 20px auto 0;
  border-radius: 6px;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

#nav_bar_box ul {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#nav_bar_box ul li {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

#nav_bar_box ul li div {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  // margin-right: 65px;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
}

.nav_name_active {
  color: #fff;
  padding: 7px 20px;
  border-radius: 6px;
  /* background: #3e7eff26; */
  background: #3e7eff;
  border-radius: 20px;
}

/* 版心 */

#content {
  background: #fff;
  padding: 0px 50px 30px;
  width: 1100px;
  /* height: 1000px; */
  margin: 20px auto 60px;
  border-radius: 6px;
  /* overflow: hidden; */
}

.teacher_box {
  width: 100%;
  // margin-top: 60px;
}

.teacher_box .teacher li {
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // height: 307px;
  /* background: pink; */
  /* border-top: 1px solid #eee; */
}
.teacher_box .teacher li:last-child {
  border: none;
  padding: 30px 0 0;
}

.teacher_box .teacher li .teacher_top {
  /* width: 210px; */
  height: 250px;
  /* margin-top: 20px; */
  display: flex;
  /* justify-content: space-between; */
}

/* 照片 */

.teacher li .teacher_top img {
  width: 210px;
  height: 250px;
}

/* 右边内容 */

.teacher_top .teacher_right {
  width: 849px;
  margin-left: 40px;
}

.teacher_top .teacher_right {
  width: 849px;
  margin-right: 41px;
}

.teacher_top .teacher_right .teacher_name {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #484848;
  margin-bottom: 20px;
}

.teacher_top .teacher_right #short_term {
  width: 20px;
  height: 3px;
  background: #545454;
  border-radius: 2px;
  margin: 19px 0 16px;
}

.teacher_top .teacher_right .major {
  // width: 160px;
  // height: 24px;
  // width: 100%;
  border: 1px solid #a3abb9;
  border-radius: 30px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #7c879c;
  // text-align: center;
  padding: 5px 17px;
  // display: inline-block;
  // margin-top: 20px;
  // line-height: 24px;
}

.teacher_top .teacher_right .division {
  width: 850px;
  // border: 1px dotted #eee;
  height: 1px;
  background: linear-gradient(
    to right,
    #eee,
    #eee 7.5px,
    transparent 7.5px,
    transparent
  );
  background-size: 15px 100%;
  margin: 30px 0;
}

.teacher_top .teacher_right .synopsis {
  width: 849px;
  height: 59px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: normal;
  color: #484848;
  line-height: 22px;
}

/* 分割线 */

.teacher li #line {
  width: 100%;
  height: 1px;
  background: #000;
  opacity: 0.1;
  margin-top: 30px;
  /* border: 1px solid #000; */
  /* margin-top: 20px; */
}

.teacher li:last-child #line {
  display: none;
}
</style>
